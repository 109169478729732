import styled, { css } from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { darken, lighten } from 'polished'
import { Link } from 'gatsby'

export const CountdownSection = styled.div`
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '#790603')};
  width: 100%;
  border-radius: 20px;
`
export const CountdownSectionContainer = styled.div`
  padding: 24px;
  text-align: center;
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
    padding: 20px;
  }
`
export const Header = styled.h2`
  font-family: 'P22MackinacProBold';
  font-size: 26px;
  line-height: 26px;
  color: #fff;
  margin: 0px;
  span {
    font-style: italic;
  }
`

export const SecondaryHeader = styled.p`
  font-family: 'FilsonProLight' !important;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  line-height: 26px;
`
export const Time = styled.h3`
  font-family: P22MackinacProMedium;
  font-size: 24px;
  line-height: 25px;
  letter-spacing: 0.5px;
  color: ${(props) => props.color || props.theme.grayDark};
  margin: 0px;
  display: block;
  text-align: center;
`
export const CountDownContainer = styled.div`
  margin: 0px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  @media (max-width: ${breakpoints.s}px) {
    padding-top: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
  }
`
export const Label = styled.p`
  font-family: P22MackinacProBold;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 1.4px;
  margin: 0px;
  text-align: center;
  color: ${(props) => props.color || '#FFF'};
  @media (max-width: ${breakpoints.m}px) {
    font-family: 12px;
    line-height: 16px;
    letter-spacing: 1.2px;
  }
`
export const ColonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  margin-top: -20px;
`
export const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const RightColumn = styled.div`
  width: 700px;
  display: flex;
  flex-direction: row;
  margin: 0px 0px 0px 30px;
  @media (max-width: ${breakpoints.l}px) {
    flex-direction: column;
    width: 100%;
    margin: 0px;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin-top: -24px;
  }
`
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const linkStyle = css`
  font-family: 'FilsonPro';
  background-color: white;
  color: black;
  text-decoration: none;
  border-radius: 28px;
  border: none;
  font-size: 18px;
  padding: 15px 30px;
  line-height: 1.28;
  letter-spacing: 0.09px;
  &:hover {
    cursor: pointer;
    color: white;
    background-color: ${(props) => darken(0.1, props.theme.holidayRed)};
  }
  &:focus {
    outline: none;
    color: white;
    box-shadow: ${(props) => `0 0 0 0.2rem ${lighten(0.1, props.theme.holidayRed)}`};
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.08px;
    padding: 13px 20px;
  }
`
export const BuyNowContainer = styled.div`
  text-align: center;
  padding: 16px 0px;
  margin: 0px;
`
export const BuyNowLink = styled(Link)`
  font-family: 'FilsonProBook';
  background-color: ${(props) => (props.bgColor ? props.bgColor : 'white')};
  color: ${(props) => (props.color ? props.color : props.theme.grayDark)};
  text-decoration: none;
  border-radius: 28px;
  border: none;
  font-size: 18px;
  padding: ${(props) => (props.big ? '15px 64px' : '15px 30px')};
  line-height: 1.28;
  letter-spacing: 0.09px;
  margin-right: ${(props) => (props.marginRight ? '16px' : '0px')};
  &:hover {
    cursor: pointer;
    color: white;
    background-color: ${(props) => darken(0.1, props.bgColor)};
  }
  &:focus {
    outline: none;
    color: white;
    box-shadow: ${(props) => `0 0 0 0.2rem ${lighten(0.1, props.bgColor)}`};
  }
  @media (max-width: 850px) {
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.08px;
    padding: ${(props) => (props.big ? '13px 64px' : '13px 20px')};
  }
  @media (max-width: 365px) {
    font-size: 14px;
    padding: ${(props) => (props.big ? '13px 64px' : '13px 16px')};
  }
  @media (max-width: 340px) {
    padding: ${(props) => (props.big ? '13px 64px' : '13px 8px')};
  }
`

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 4px;
  width: 46px;
  margin-top: 0px;
`

export const CalNYCol = styled.div`
  width: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  h1 {
    display: inline;
  }
  @media (max-width: ${breakpoints.l}px) {
    width: 60px;
  }
`
export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 10px 10px 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${breakpoints.s}px) {
    padding: 5px 5px 0px 5px;
  }
`
export const CalNYLabel = styled(Label)`
  color: ${(props) => props.color};
  padding-top: 10px;
  font-family: 'P22MackinacPro';
  font-size: 14px;
  line-height: 19px;
  font-weight: 800;
`
export const HideOnMobile = styled.div`
  display: block;
  @media (max-width: ${breakpoints.s}px) {
    display: none;
  }
`
export const HideOnDesktop = styled.div`
  display: none;
  @media (max-width: ${breakpoints.s}px) {
    display: block;
  }
`
export const DealHeadline = styled.p`
  font-size: 20px;
  color: ${(props) => props.theme.grayDark};
  font-family: 'FilsonProBold';
  text-align: center;
  span {
    color: #954341;
  }
`
export const DealCard = styled.div`
  margin-top: 16px;
`
export const DealContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  @media (max-width: ${breakpoints.s}px) {
    margin-top: -20px;
  }
`
export const DealTitle = styled.p`
  font-family: 'FilsonProBold';
  text-align: right;
  margin: 0px 5px 8px;
  color: ${(props) => props.theme.grayDark};
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
  }
  @media (max-width: 400px) {
    font-size: 12px;
  }
`
export const DealPrice = styled.p`
  text-decoration: line-through;
  text-align: right;
  margin: 0px 0px;
  color: ${(props) => props.theme.grayDark};
  span {
    color: #954341;
    font-family: FilsonProBold;
    text-decoration: none !important;
  }
`
export const ImageContainer = styled.div`
  min-width: ${(props) => (props.cal ? '350px' : '310px')};
  @media (max-width: 1450px) {
    min-width: 280px;
  }
  @media (max-width: ${breakpoints.xl}px) {
    min-width: 250px;
    margin: auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    min-width: 200px;
  }
`

export const AmazonFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: ${breakpoints.l}px) {
    width: 400px;
    margin: auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    display: block;
    max-width: 100%;
  }
`
